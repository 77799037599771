
import React, { useMemo } from "react";
import objectPath from "object-path";
import { useHtmlClassService } from "../../_core/AppLayout";
import { SearchDropdown } from "../extras/dropdowns/search/SearchDropdown";
import { UserNotificationsDropdown } from "../extras/dropdowns/UserNotificationsDropdown";
import { QuickUserToggler } from "../extras/QuiclUserToggler";
import { useSelector, shallowEqual } from "react-redux"

export function Topbar() {
  const uiService = useHtmlClassService();
  const sessionkeyStatus = useSelector((state) => state.auth.sessionkeyvalue, shallowEqual)

  const layoutProps = useMemo(() => {
    return {
      viewSearchDisplay: objectPath.get(
        uiService.config,
        "extras.search.display"
      ),
      viewNotificationsDisplay: objectPath.get(
        uiService.config,
        "extras.notifications.display"
      ),
      viewQuickActionsDisplay: objectPath.get(
        uiService.config,
        "extras.quick-actions.display"
      ),
      viewCartDisplay: objectPath.get(uiService.config, "extras.cart.display"),
      viewQuickPanelDisplay: objectPath.get(
        uiService.config,
        "extras.quick-panel.display"
      ),
      viewLanguagesDisplay: objectPath.get(
        uiService.config,
        "extras.languages.display"
      ),
      viewUserDisplay: objectPath.get(uiService.config, "extras.user.display"),
    };
  }, [uiService]);


  return (
    <div className="topbar">
      {layoutProps.viewSearchDisplay && <SearchDropdown sessionkeyStatusParent={sessionkeyStatus} />}
      <div className={`topbar-info`}>
        {layoutProps.viewNotificationsDisplay && <UserNotificationsDropdown />}
        {layoutProps.viewUserDisplay && <QuickUserToggler />}
      </div>
    </div>
  );
}
